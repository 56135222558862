exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-api-js": () => import("./../../../src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-contacts-payment-jsx": () => import("./../../../src/pages/contacts/payment.jsx" /* webpackChunkName: "component---src-pages-contacts-payment-jsx" */),
  "component---src-pages-hotels-js": () => import("./../../../src/pages/hotels.js" /* webpackChunkName: "component---src-pages-hotels-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-reconfirmation-jsx": () => import("./../../../src/pages/reconfirmation.jsx" /* webpackChunkName: "component---src-pages-reconfirmation-jsx" */),
  "component---src-pages-register-bmt-jsx": () => import("./../../../src/pages/register/bmt.jsx" /* webpackChunkName: "component---src-pages-register-bmt-jsx" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-register-success-jsx": () => import("./../../../src/pages/register/success.jsx" /* webpackChunkName: "component---src-pages-register-success-jsx" */),
  "component---src-pages-transfers-js": () => import("./../../../src/pages/transfers.js" /* webpackChunkName: "component---src-pages-transfers-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-job-vacancy-js": () => import("./../../../src/templates/jobVacancy.js" /* webpackChunkName: "component---src-templates-job-vacancy-js" */)
}

